import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isSiteCreationDisable
    ? _c(
        VContainer,
        { attrs: { "grid-list-xl": "" } },
        [
          _c(
            VFlex,
            { attrs: { xs9: "" } },
            [_c("view-title", { attrs: { showBreadCrum: false } })],
            1
          ),
          _c("DisabledBanner")
        ],
        1
      )
    : _c(
        VContainer,
        { attrs: { "grid-list-xl": "" } },
        [
          _c(
            VForm,
            {
              ref: "form",
              staticClass: "settings",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c("view-title"),
                      _c("intersection-observer", {
                        on: { intersect: _vm.intersect }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs10: "" } },
                    [
                      _c(
                        VLayout,
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _c("site-details", {
                                directives: [
                                  {
                                    name: "observe-visibility",
                                    rawName: "v-observe-visibility",
                                    value: _vm.visibilityChanged,
                                    expression: "visibilityChanged"
                                  }
                                ],
                                ref: "siteDetails",
                                attrs: {
                                  id: "site-detail",
                                  isNewSite: _vm.isNewSite
                                },
                                model: {
                                  value: _vm.site,
                                  callback: function($$v) {
                                    _vm.site = $$v
                                  },
                                  expression: "site"
                                }
                              })
                            ],
                            1
                          ),
                          _c(VFlex, { attrs: { xs12: "" } }),
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _c("payment", {
                                directives: [
                                  {
                                    name: "observe-visibility",
                                    rawName: "v-observe-visibility",
                                    value: _vm.visibilityChanged,
                                    expression: "visibilityChanged"
                                  }
                                ],
                                ref: "paymentRef",
                                attrs: {
                                  payment: _vm.payment,
                                  keystore: _vm.keystore,
                                  id: "payment-detail",
                                  refund: _vm.payment.refund
                                },
                                on: {
                                  "update:refund": function($event) {
                                    return _vm.$set(
                                      _vm.payment,
                                      "refund",
                                      $event
                                    )
                                  },
                                  editKeystore: _vm.editKeystore,
                                  editPayment: _vm.editPayment
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _vm.isCashless
                                ? _c("cashless", {
                                    directives: [
                                      {
                                        name: "observe-visibility",
                                        rawName: "v-observe-visibility",
                                        value: _vm.visibilityChanged,
                                        expression: "visibilityChanged"
                                      }
                                    ],
                                    attrs: {
                                      siteId: _vm.site.id,
                                      keystore: _vm.keystore,
                                      formRef: _vm.$refs.form,
                                      id: "cashless-pay"
                                    },
                                    model: {
                                      value: _vm.cashless,
                                      callback: function($$v) {
                                        _vm.cashless = $$v
                                      },
                                      expression: "cashless"
                                    }
                                  })
                                : _c("badge-pay", {
                                    directives: [
                                      {
                                        name: "observe-visibility",
                                        rawName: "v-observe-visibility",
                                        value: _vm.visibilityChanged,
                                        expression: "visibilityChanged"
                                      }
                                    ],
                                    attrs: {
                                      siteId: _vm.site.id,
                                      payment: _vm.payment,
                                      formRef: _vm.$refs.form,
                                      cashless: _vm.cashless,
                                      id: "badge-pay"
                                    },
                                    on: {
                                      "update:cashless": function($event) {
                                        _vm.cashless = $event
                                      }
                                    },
                                    model: {
                                      value: _vm.badge_pay,
                                      callback: function($$v) {
                                        _vm.badge_pay = $$v
                                      },
                                      expression: "badge_pay"
                                    }
                                  })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { attrs: { xs12: "" } },
                            [
                              _c("mealplan", {
                                directives: [
                                  {
                                    name: "observe-visibility",
                                    rawName: "v-observe-visibility",
                                    value: _vm.visibilityChanged,
                                    expression: "visibilityChanged"
                                  }
                                ],
                                attrs: {
                                  mealplan: _vm.mealplan,
                                  locationGroupName: _vm.site.name,
                                  siteId: _vm.site.id,
                                  id: "mealplan-detail",
                                  configLevel: "SITE"
                                },
                                on: {
                                  "update:mealplan": function($event) {
                                    _vm.mealplan = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { staticClass: "mb-5", attrs: { xs12: "" } },
                            [
                              _c("loyalty", {
                                directives: [
                                  {
                                    name: "observe-visibility",
                                    rawName: "v-observe-visibility",
                                    value: _vm.visibilityChanged,
                                    expression: "visibilityChanged"
                                  }
                                ],
                                attrs: { id: "loyalty-detail" },
                                model: {
                                  value: _vm.loyalty,
                                  callback: function($$v) {
                                    _vm.loyalty = $$v
                                  },
                                  expression: "loyalty"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { staticClass: "mb-5", attrs: { xs12: "" } },
                            [
                              _c("promotion", {
                                directives: [
                                  {
                                    name: "observe-visibility",
                                    rawName: "v-observe-visibility",
                                    value: _vm.visibilityChanged,
                                    expression: "visibilityChanged"
                                  }
                                ],
                                attrs: {
                                  promotions: _vm.promotions,
                                  "original-promos":
                                    _vm.backup.publicConfig.promotions,
                                  id: "promotion-detail"
                                },
                                on: {
                                  "update:promotions": function($event) {
                                    _vm.promotions = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { staticClass: "mb-5", attrs: { xs12: "" } },
                            [
                              _c("kds", {
                                attrs: {
                                  kds_units: _vm.kds_units,
                                  id: "kds-conf"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { staticClass: "mb-5", attrs: { xs12: "" } },
                            [
                              _c("deliveryDropoffLocations", {
                                directives: [
                                  {
                                    name: "observe-visibility",
                                    rawName: "v-observe-visibility",
                                    value: _vm.visibilityChanged,
                                    expression: "visibilityChanged"
                                  }
                                ],
                                attrs: {
                                  "drop-off-locations": _vm.drop_off_locations,
                                  "original-drop-off-locations":
                                    _vm.backup.drop_off_locations,
                                  isDisabled: !_vm.isCDLLocation,
                                  id: "drop-off-locations"
                                },
                                on: {
                                  "update:dropOffLocations": function($event) {
                                    _vm.drop_off_locations = $event
                                  },
                                  "update:drop-off-locations": function(
                                    $event
                                  ) {
                                    _vm.drop_off_locations = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { staticClass: "mb-5", attrs: { xs12: "" } },
                            [
                              _c("apex", {
                                attrs: { id: "apex-config", apex: _vm.apex },
                                on: {
                                  "update:apex": function($event) {
                                    _vm.apex = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { staticClass: "mb-5", attrs: { xs12: "" } },
                            [
                              _c("frictionless", {
                                directives: [
                                  {
                                    name: "observe-visibility",
                                    rawName: "v-observe-visibility",
                                    value: _vm.visibilityChanged,
                                    expression: "visibilityChanged"
                                  }
                                ],
                                attrs: {
                                  id: "frictionless",
                                  withDialog: false,
                                  frictionlessProps: _vm.frictionlessData,
                                  configLevel: "SITE"
                                },
                                on: {
                                  "update:frictionlessProps": function($event) {
                                    _vm.frictionlessData = $event
                                  },
                                  "update:frictionless-props": function(
                                    $event
                                  ) {
                                    _vm.frictionlessData = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            VFlex,
                            { staticClass: "mb-5", attrs: { xs12: "" } },
                            [
                              _c("onemarket", {
                                directives: [
                                  {
                                    name: "observe-visibility",
                                    rawName: "v-observe-visibility",
                                    value: _vm.visibilityChanged,
                                    expression: "visibilityChanged"
                                  }
                                ],
                                attrs: {
                                  id: "onemarket",
                                  withDialog: false,
                                  onemarketProps: _vm.onemarketData
                                },
                                on: {
                                  "update:onemarketProps": function($event) {
                                    _vm.onemarketData = $event
                                  },
                                  "update:onemarket-props": function($event) {
                                    _vm.onemarketData = $event
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c("LocalMenuGroupLink", {
                            attrs: {
                              id: "local-menu-group-link",
                              linkedLocalMenuGroups: _vm.linkedLocalMenuGroups,
                              linkedLocalMenuGroupsBackup:
                                _vm.backup.linkedLocalMenuGroups
                            },
                            on: {
                              "update:linkedLocalMenuGroups": function($event) {
                                _vm.linkedLocalMenuGroups = $event
                              },
                              "update:linked-local-menu-groups": function(
                                $event
                              ) {
                                _vm.linkedLocalMenuGroups = $event
                              },
                              "update:linkedLocalMenuGroupsBackup": function(
                                $event
                              ) {
                                return _vm.$set(
                                  _vm.backup,
                                  "linkedLocalMenuGroups",
                                  $event
                                )
                              },
                              "update:linked-local-menu-groups-backup": function(
                                $event
                              ) {
                                return _vm.$set(
                                  _vm.backup,
                                  "linkedLocalMenuGroups",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs2: "" } },
                    [
                      _c("tableOfContents", {
                        ref: "toc",
                        attrs: {
                          customClass: _vm.tocCustomClass,
                          items: _vm.tocItems,
                          isCashless: _vm.isCashless
                        },
                        model: {
                          value: _vm.tocItems,
                          callback: function($$v) {
                            _vm.tocItems = $$v
                          },
                          expression: "tocItems"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("save-footer", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isModified,
                    expression: "isModified"
                  }
                ],
                attrs: {
                  cancelAction: _vm.cancel,
                  saveLabel: _vm.isNewSite ? "Create Site" : "Save Changes",
                  saveAction: _vm.save
                }
              })
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }